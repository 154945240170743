














import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { BusyObject } from '@/models/Busy';
import { BrandsService, Brand as ApiBrand } from '@/api/braendz';

import BrandDetails from '@/components/Brands/BrandDetails.vue'

@Component({
    components: {
        BrandDetails
    },
    metaInfo() {
        return {
            title: this.$i18n.t("brand.view.title", { brandName: (this as unknown as Brand)?.brand?.object?.name ?? this.$i18n.t('brand.view.brandNameLoading') }).toString()
        };
    }
})
export default class Brand extends Vue {
    // Properties:
    public brand = new BusyObject<ApiBrand>();
    
    // Getter:
    public get Id(): string | undefined {
        return this.$route.params?.id;
    }

    // Lifecycle Methods:
    public mounted(): void {
        this.refresh();
    }

    // Methods:
    public async refresh(): Promise<void> {
        await this.brand.load(async () => {
            if(!this.Id) {
                return null;
            }
            return await BrandsService.getBrand(this.Id);
        });
    }
}
